.image-statistics-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer; 
  }
  
  .image-statistics-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .card-image {
    width: 71px;
    height: 71px;
    border-radius: 60%;
  }
  