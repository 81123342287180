.signin-dashboard-container {
  /* background: rgba(255, 255, 255, 0.5); */
  background:  rgba(8, 8, 8, 0.5); 
  border-radius: 10px;
  padding: 10px;
  max-width: 900px;
  margin: 20px auto 0 auto;
}

.dashboard-container {
  /* Add background overlay with transparency */
  background: rgba(216, 209, 209, 0.5); 
  border-radius: 10px;
  padding: 10px;
  max-width: 600px;
  margin: 20px auto 0 auto;
}

.druthzuci {
  max-width: 100%; 
}

.dz{
  background:  rgba(243, 240, 240, 0.5); 
}
